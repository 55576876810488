import React from "react";
import { Typography, Grid, Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectFactoryActionNeededData,
  selectFactoryData,
} from "../../selectors/factory.selector";
import { selectAuditListForFactory, selectCapListForFactory } from "../../selectors/audit.selector";

import FactoryActionNeeded from "./factoryActionNeeded.component";

import FactoryMostRecentAuditDetails from "./factoryMostRecentAuditDetails.component";

const FacilityComplianceStatus = () => {

  const params = useParams();
  const selectFactory = useSelector(selectFactoryData);

  const factoryCapData = useSelector((state) =>
    selectCapListForFactory(state, params.factoryId)
  );

  // Filter out items with status "Open"
  const openCapData = factoryCapData.filter(item => item.status === "Open");

  return (
    <>
      {/* <Typography
        sx={{
          color: "#000",
          fontSize: 18,
          fontWeight: 700,
          mb: 2,
          mt: 2,
        }}
      >
        Information from last audit
      </Typography>
      <Grid container spacing={4} style={{ marginTop: -16 }}>
        <Grid item xs={6}>
          <FactoryRiskCard
            key={"enhancedRisk"}
            title={"Enhanced Risk"}
            riskScore={
              selectFactory?.metadata?.enhancedRisk?.totalScore ?? "No Data"
            }
            details={[
              {
                label: "Category 1",
                subLabel:
                  selectFactory?.metadata?.inherentRisk?.category1 || "TBD",
                value:
                  selectFactory?.metadata?.enhancedRisk?.category1Score ?? "-",
              },
              {
                label: "Category 2",
                subLabel:
                  selectFactory?.metadata?.inherentRisk?.category2 || "TBD",
                value:
                  selectFactory?.metadata?.enhancedRisk?.category2Score ?? "-",
              },
              {
                label: "Category 3",
                subLabel:
                  selectFactory?.metadata?.inherentRisk?.category1 || "TBD",
                value:
                  selectFactory?.metadata?.enhancedRisk?.category1Score ?? "-",
              },
              {
                label: "Category 4",
                subLabel:
                  selectFactory?.metadata?.inherentRisk?.category2 || "TBD",
                value:
                  selectFactory?.metadata?.enhancedRisk?.category2Score ?? "-",
              },
            ]}
          />
        </Grid>
        <Grid item xs={6}>

          <FactoryRiskCard
            key={"inherentRisk"}
            title={"Inherent Risk"}
            riskScore={
              selectFactory?.metadata?.inherentRisk?.totalScore ?? "No Data"
            }
            details={[
              {
                label: "Country",
                subLabel: selectFactory?.metadata?.inherentRisk?.country || "-",
                value:
                  selectFactory?.metadata?.inherentRisk?.countryScore ?? "-",
              },
              {
                label: "Sourcing TML",
                subLabel:
                  selectFactory?.metadata?.inherentRisk?.sourcingTml ?? "-",
                value:
                  selectFactory?.metadata?.inherentRisk?.sourcingTmlScore ??
                  "-",
              },
              {
                label: "Audit Type",
                subLabel:
                  selectFactory?.metadata?.inherentRisk?.auditType || "-",
                value:
                  selectFactory?.metadata?.inherentRisk?.auditTypeScore ?? "-",
              },
              {
                label: "Frequency of Monitoring",
                subLabel:
                  selectFactory?.metadata?.inherentRisk?.frequency || "-",
                value:
                  selectFactory?.metadata?.inherentRisk?.frequencyScore ?? "-",
              },
            ]}
          />
        </Grid>
      </Grid> */}

      {selectFactory?.latestAudit ? (
        <FactoryMostRecentAuditDetails selectedFactory={selectFactory} />
      ) : (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "50px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 16,
              color: "#8B8D97",
            }}
          >
            No audit exist for site status
          </Typography>
        </Box>
      )}

      <Grid item>
        {openCapData?.length > 0 && (
          <FactoryActionNeeded capDetails={openCapData} />
        )}
      </Grid>
    </>
  );
};

export default FacilityComplianceStatus;
