import React, { useState } from "react";
import { Modal, Grid, Box, Paper } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import {
  selectListOfAuditsInCapManagement,
  selectListOfAuditsInCapManagementByFactoryId,
  selectModalStateForManageCAPs,
} from "../selectors/audit.selector";
import { setModalStateForManageCAPs } from "../reducers/audit.reducer";
import AuditReportDetails from "../pages/auditReportDetails.page";
import FactoryAuditListCard from "./FacilityDetails/factoryAuditHistoryListCard.component";
import AuditListInCapManagementProgress from "./FacilityDetails/auditListInCapManagementProgress.component";
import AuditReportDetailsAppbar from "./AuditDetails/auditReportDetailsAppbar.component";

import CapManagementModalAppbar from "./AuditDetails/capManagementModalAppbar.component";

const CapManagementModal = ({ factoryId = null }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const modalStateForManageCAPs = useSelector(selectModalStateForManageCAPs);
  const dispatch = useDispatch();
  const handleDrawerClose = () => {
    dispatch(setModalStateForManageCAPs(false));
  };
  const selectAudits = useSelector(selectListOfAuditsInCapManagement);

  return (
    <Modal
      open={modalStateForManageCAPs}
      onClose={handleDrawerClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "80vh",
        borderRadius: "12px",
      }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
        },
      }}
    >
      <Paper
        sx={{
          width: "100%",
          maxWidth: "90%",
          height: "90%",
          borderRadius: "12px",
          padding: 2,
          overflow: "auto",
        }}
      >
        {" "}
        <Box sx={{ width: "100%", position: "sticky", top: 10, zIndex: 1000 }}>
          <CapManagementModalAppbar handleDrawerClose={handleDrawerClose} />
          {/* <Divider flexItem style={{ width: "98%", margin: "1px 16px" }} /> */}
        </Box>
        <AuditListInCapManagementProgress selectAudits={selectAudits} />
      </Paper>
    </Modal>
  );
};

export default CapManagementModal;
