import React, { useCallback, useState, useEffect } from "react";

import {
  Container,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Chip,
  Box,
  TextField,
  Paper,
  Tooltip,
} from "@mui/material";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";

import CustomChipLabel from "./customChipLabel.component";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAuditCAPStatus,
  selectAuditData,
  selectAuditIssueDetails,
  selectSelectedAuditIssueId,
} from "../../selectors/audit.selector";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { getValidData } from "../../utils/lib";
import { debounce } from "lodash";
import {
  selectAutoSaveTimer,
  selectSaveStatus,
} from "../../selectors/misc.selector";
import { saveStatus, setSaveStatus } from "../../reducers/misc.reducer";
import { useParams } from "react-router-dom";
import { updateAuditData, updateIssueData } from "../../reducers/audit.reducer";
import { CheckCircleOutline } from "@mui/icons-material";
import { AuditStyles, ComponentStyles } from "../../styles";
import { Select, MenuItem, FormControl } from "@mui/material";
import CAPChangesUI from "./capApprovedUploadImageProof.component";
import CapApprovedUploadImageProof from "./capApprovedUploadImageProof.component";
import AuditReportCapDetailsSection from "./auditReportCapDetailsSection.component";

const RecommendationsList = ({ title, items }) => (
  <Paper elevation={0} sx={{ mb: 2 }}>
    <Grid container>
      <Grid item xs={1}>
        <LightbulbOutlinedIcon sx={{ pt: 0.5, color: "#667085" }} />
      </Grid>
      <Grid item xs={11}>
        <List dense sx={{ p: 0 }}>
          {items.map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemText
                primary={
                  <Typography sx={AuditStyles.auditReportFieldValue}>
                    {item}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  </Paper>
);

const AiGeneratedField = ({ label, children }) => (
  <Box>
    <Typography
      sx={{
        color: "#475467",
        fontSize: "16px",
        fontWeight: 700,
        mb: 1,
        display: "flex",
        alignItems: "center",
      }}
    >
      {label}
      <Tooltip title="Generated by AI">
        <AutoAwesomeIcon sx={{ fontSize: 16, ml: 0.5, color: "darkgrey" }} />
      </Tooltip>
    </Typography>
    {children}
  </Box>
);
const RootCauseAnalysis = ({ items }) => (
  <Paper elevation={0} sx={{ mb: 2 }}>
    <List dense sx={{ p: 0 }}>
      {items.map((item, index) => (
        <ListItem key={index} disablePadding>
          <ListItemText
            primary={
              <Typography
                sx={{ color: "#000", fontSize: "16px", fontWeight: 400, pl: 1 }}
              >
                {item}
              </Typography>
            }
          />
        </ListItem>
      ))}
    </List>
  </Paper>
);

const AuditReport = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const data = useSelector(selectAuditIssueDetails);
  const saveStatus = useSelector(selectSaveStatus);
  const [random, setRandom] = useState(0);
  const selectedIssueId = useSelector(selectSelectedAuditIssueId);
  const selectedAuditData = useSelector(selectAuditData);
  const [correctiveActionPlan, setCorrectiveActionPlan] = useState("");
  const [longTermActionPlan, setLongTermActionPlan] = useState("");
  const [rootCauseAnalysis, setRootCauseAnalysis] = useState("");
  const [responsiblePerson, setResponsiblePerson] = useState("");
  const [targetDate, setTargetDate] = useState(null);
  const [rootCauseOption, setRootCauseOption] = useState("");
  const auditCapStatus = useSelector(selectAuditCAPStatus);
  console.log("auditCapStatus", auditCapStatus);
  const debouncedSaveNote = useCallback(
    debounce((newNote) => {
      dispatch(
        updateAuditData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: { capDetailsNote: newNote },
          issueId: selectedIssueId,
        })
      );
    }, 1000),
    []
  );

  const debouncedSaveCorrectiveActionPlan = useCallback(
    debounce((newActionPlan, issueId) => {
      dispatch(
        updateIssueData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: { "actionPlan.immediateCorrectiveAction": newActionPlan },
          issueId,
        })
      );
    }, 1000),
    []
  );

  const debouncedSaveLongTermActionPlan = useCallback(
    debounce((newActionPlan, issueId) => {
      dispatch(
        updateIssueData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: {
            "actionPlan.longTermPreventativeAction": newActionPlan,
          },
          issueId,
        })
      );
    }, 1000),
    []
  );
  const debouncedSaveTargetDate = useCallback(
    debounce((newTargetDate, issueId) => {
      dispatch(
        updateIssueData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: { "actionPlan.deadline": newTargetDate },
          issueId,
        })
      );
    }, 1000),
    []
  );

  const debouncedSaveRootCauseAnalysis = useCallback(
    debounce((newRootCauseAnalysis, issueId) => {
      dispatch(
        updateIssueData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: { "actionPlan.rootCauseAnalysis": newRootCauseAnalysis },
          issueId,
        })
      );
    }, 1000),
    []
  );
  const debouncedSaveResponsiblePerson = useCallback(
    debounce((newResponsiblePerson, issueId) => {
      dispatch(
        updateIssueData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: { "actionPlan.responsiblePerson": newResponsiblePerson },
          issueId,
        })
      );
    }, 1000),
    []
  );
  const debouncedSaveRootCauseOption = useCallback(
    debounce((newRootCauseOption, issueId) => {
      dispatch(
        updateIssueData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: { "actionPlan.rootCause": newRootCauseOption },
          issueId,
        })
      );
    }, 1000),
    []
  );
  const handleCorrectiveActionPlanChange = (event) => {
    const newValue = event.target.value;
    setCorrectiveActionPlan(newValue);

    debouncedSaveCorrectiveActionPlan(newValue, selectedIssueId);
  };

  const handleLongTermActionPlanChange = (event) => {
    const newValue = event.target.value;
    setLongTermActionPlan(newValue);
    debouncedSaveLongTermActionPlan(newValue, selectedIssueId);
  };

  const handleRootCauseAnalysisChange = (event) => {
    const newValue = event.target.value;
    setRootCauseAnalysis(newValue);
    debouncedSaveRootCauseAnalysis(newValue, selectedIssueId);
  };
  const handleResponsiblePersonChange = (event) => {
    const newValue = event.target.value;
    setResponsiblePerson(newValue);
    debouncedSaveResponsiblePerson(newValue, selectedIssueId);
  };
  const handleTargetDateChange = (event) => {
    const newValue = event.target.value;
    setTargetDate(newValue);
    debouncedSaveTargetDate(newValue, selectedIssueId);
  };
  const handleRootCauseOptionChange = (event) => {
    const newValue = event.target.value;
    setRootCauseOption(newValue);
    debouncedSaveRootCauseOption(newValue, selectedIssueId);
  };

  useEffect(() => {
    if (saveStatus === "saved") {
      setTimeout(() => dispatch(setSaveStatus("")), 1000);
    }
  }, [saveStatus]);
  console.log(data?.actionPlan?.deadline);
  useEffect(() => {
    if (data?.actionPlan) {
      if (data?.actionPlan?.immediateCorrectiveAction) {
        setCorrectiveActionPlan(data?.actionPlan?.immediateCorrectiveAction);
      }
      if (data?.actionPlan?.longTermPreventativeAction) {
        setLongTermActionPlan(data?.actionPlan?.longTermPreventativeAction);
      }
      if (data?.actionPlan?.rootCauseAnalysis) {
        setRootCauseAnalysis(data?.actionPlan?.rootCauseAnalysis);
      }
      if (data?.actionPlan?.responsiblePerson) {
        setResponsiblePerson(data?.actionPlan?.responsiblePerson);
      }
      if (data?.actionPlan?.rootCause) {
        setRootCauseOption(data?.actionPlan?.rootCause);
      }
      if (data.actionPlan.deadline) {
        setTargetDate(
          new Date(data.actionPlan.deadline).toISOString().split("T")[0]
        );
      } else {
        console.log("target date not present", targetDate);
        setTargetDate(null);
      }
    }
  }, [data]);

  useEffect(() => {
    setCorrectiveActionPlan(data?.actionPlan?.immediateCorrectiveAction || "");
    setLongTermActionPlan(data?.actionPlan?.longTermPreventativeAction || "");
    setRootCauseAnalysis(data?.actionPlan?.rootCauseAnalysis || "");
    setResponsiblePerson(data?.actionPlan?.responsiblePerson || "");

    setTargetDate(
      data?.actionPlan?.deadline
        ? new Date(data?.actionPlan?.deadline).toISOString().split("T")[0]
        : null
    );
    setRootCauseOption(data?.actionPlan?.rootCause || "");
  }, [selectedIssueId]);

  const [note, setNote] = useState("");
  useEffect(() => {
    setNote("");
    setNote(getValidData(data.note));
  }, [selectedIssueId]);

  const handleNoteChange = (event) => {
    const newValue = event.target.value;
    dispatch(setSaveStatus("saving"));
    setNote(newValue);

    debouncedSaveNote(newValue);
  };
  const [selectedValue, setSelectedValue] = React.useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const options = [
    {
      value: "Inadequate Knowledge or Awareness",
      label: "Inadequate Knowledge or Awareness",
    },
    {
      value: "Lack of Management Commitment",
      label: "Lack of Management Commitment",
    },
    {
      value: "Lack of Policies & Procedures",
      label: "Lack of Policies & Procedures",
    },
    {
      value: "poor_implementation_practices",
      label: "Poor Implementation Practices",
    },
    { value: "Environmental Conditions", label: "Environmental Conditions" },
    { value: "Resource Constraints", label: "Resource Constraints" },
    {
      value: "Inefficient Processes & Procedures",
      label: "Inefficient Processes & Procedures",
    },
    { value: "System Malfunctions", label: "System Malfunctions" },
  ];
  useEffect(() => {
    setRandom(Math.floor(Math.random() * 10));
  }, []);

  return (
    <Container sx={{ mt: 4 }}>
      <Box sx={{ p: 2 }}>
        <Typography
          sx={{
            ...AuditStyles.auditReportFieldValue,
            fontSize: "24px",
            fontWeight: 400,
            mt: 2,
            mb: 3,
          }}
        >
          {data?.issueSummary || "-"}
        </Typography>

        {auditCapStatus === "OPEN" ? (
          <AuditReportCapDetailsSection
            auditCapStatus={auditCapStatus}
            data={data}
          />
        ) : (
          <>
            <Accordion
              disableGutters
              elevation={0}
              square
              sx={{
                padding: "12px",
                borderRadius: "12px",
                border: "1px solid #D0D5DD",
                background: "#FFF",
                boxShadow: "none",
                "&:before": {
                  display: "none",
                },
                "&.Mui-expanded": {
                  margin: 0,
                },
                "& .MuiAccordion-root": {
                  boxShadow: "none",
                  borderRadius: "12px",
                },
                "& .MuiPaper-root": {
                  boxShadow: "none",
                  borderRadius: "12px",
                },
                "& .MuiAccordionSummary-root": {
                  borderRadius: "12px",
                },
                "& .MuiAccordionDetails-root": {
                  borderRadius: "0 0 12px 12px",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  
                  minHeight: "48px",
                  "&.Mui-expanded": {
                    minHeight: "48px",
                    borderBottom: "1px solid #E0E0E0",
                    borderRadius: "0px",
                  },
                  "& .MuiAccordionSummary-content": {
                    margin: "12px 0",
                  },
                  "& .MuiAccordionSummary-content.Mui-expanded": {
                    margin: "12px 0",
                  },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{ ...AuditStyles.fieldTitle, mb: 1 }}
                  >
                    Finding Details
                  </Typography>
                  <Typography sx={AuditStyles.auditReportFieldValue}>
                    {data?.issueDetail || "-"}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {/* Details Section */}
                <AuditReportCapDetailsSection
                  auditCapStatus={auditCapStatus}
                  data={data}
                />
              </AccordionDetails>
            </Accordion>
          </>
        )}
        {auditCapStatus === "BRAND_APPROVED_PLAN" ||
        auditCapStatus === "SITE_ADDING_PROOF" ||
        auditCapStatus === "SITE_ADDED_PROOF" ||
        auditCapStatus === "BRAND_APPROVED_PROOF" ||
        auditCapStatus === "CLOSED" ? (
          <CapApprovedUploadImageProof
            immediateActions={data?.actionPlan?.immediateCorrectiveAction}
            longTermActions={data?.actionPlan?.longTermPreventativeAction}
            targetDate={data?.actionPlan?.deadline}
            responsiblePerson={data?.actionPlan?.responsiblePerson}
            rootCauseAnalysis={data?.actionPlan?.rootCauseAnalysis}
            status={auditCapStatus}
            rootCause={data?.actionPlan?.rootCause}
          />
        ) : auditCapStatus !== "OPEN" ? (
          <>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    color: "#475467",
                    fontSize: "20px",
                    fontWeight: 700,
                    textDecorationLine: "underline",
                    mb: 2,
                    mt: 3,
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                    hyphens: "auto",
                  }}
                >
                  Site Input
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    color: "#475467",
                    fontSize: "20px",
                    fontWeight: 700,
                    textDecorationLine: "underline",
                    mb: 2,
                    mt: 3,

                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                    hyphens: "auto",
                  }}
                >
                  CAP Recommendations
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} width="60%">
                  <Grid item xs={5}>
                    <Typography
                      sx={{
                        color: "#475467",
                        fontSize: "16px",
                        fontWeight: 700,
                        mb: 1,
                      }}
                    >
                      Responsible Person
                    </Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder=""
                      value={responsiblePerson}
                      onChange={handleResponsiblePersonChange}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      sx={{
                        ...AuditStyles.fieldTitle,
                        mb: 1,
                      }}
                    >
                      Target Completion Date
                    </Typography>
                    <TextField
                      type="date"
                      fullWidth
                      value={targetDate || ""}
                      onChange={handleTargetDateChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                  }}
                >
                  Root Cause Analysis
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <Select
                    sx={{
                      borderRadius: "8px",
                      border: "1px solid #D0D5DD",
                      background: "#FFF",
                      boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                      // padding: "10px 14px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                    value={rootCauseOption}
                    onChange={handleRootCauseOptionChange}
                    displayEmpty
                    renderValue={(selected) => selected || "Select an option"}
                  >
                    {options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={7}></Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  placeholder="Enter root cause analysis..."
                  value={rootCauseAnalysis}
                  onChange={handleRootCauseAnalysisChange}
                  sx={{ mb: 2 }}
                  required={true}
                />
              </Grid>
              <Grid item xs={6}>
                <RootCauseAnalysis
                  items={
                    data?.rootCause ? String(data.rootCause).split("\n") : []
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Typography fontSize="16px" fontWeight={700}>
                  Immediate Corrective Action Plan
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  placeholder="Enter immediate corrective action plan..."
                  value={correctiveActionPlan}
                  onChange={handleCorrectiveActionPlanChange}
                  sx={{ mb: 2 }}
                  required={true}
                />
              </Grid>
              <Grid item xs={6}>
                <RecommendationsList
                  title=""
                  items={
                    data?.capRecommendations?.immediateCAP
                      ? String(data.capRecommendations.immediateCAP).split("\n")
                      : []
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Typography fontSize="16px" fontWeight={700}>
                  Long Term Preventative Action
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  placeholder="Enter long term preventative action..."
                  value={longTermActionPlan}
                  onChange={handleLongTermActionPlanChange}
                  sx={{ mb: 2 }}
                  required={true}
                />
              </Grid>
              <Grid item xs={6}>
                <RecommendationsList
                  title=""
                  items={
                    data?.capRecommendations?.longTermPreventativeAction
                      ? String(
                          data.capRecommendations.longTermPreventativeAction
                        ).split("\n")
                      : []
                  }
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <></>
        )}
        {/* <Typography
          sx={{
            color: "#000",
            textAlign: "right",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "24px",
          }}
        >
          Last Updated by AuditSense AI
        </Typography> */}
      </Box>
    </Container>
  );
};

export default AuditReport;
