import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Divider,
  Card,
  Switch,
  Button,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectSupplierNameFromId } from "../selectors/supplier.selector";
import { selectSupplierId } from "../selectors/login.selector";
import TopBreadcrumbs from "../components/topBreadcrumbs.component";

import LeftSideNavbar from "../components/leftSideNavbar.component";
import {
  selectFacilityPageTab,
  selectFactoryData,
  selectFactoryNameFromId,
  selectFactoryTierFromId,
} from "../selectors/factory.selector";
import FacilityInformation from "../components/FacilityDetails/facilityInformation.component";
import FacilityInternalInformation from "../components/FacilityDetails/facilityInternalInformation.component";
import FacilityTabs from "../components/FacilityDetails/facilityTabs.component";
import FacilityComplianceStatus from "../components/FacilityDetails/facilityComplianceStatus.component";
import FacilityAuditList from "../components/FacilityDetails/facilityAuditList.component";
import FacilityInformationDrawer from "../components/FacilityDetails/facilityInformationDrawer.component";
import { getFactoryById } from "../reducers/factory.reducer";
import EditToggleSwitch from "../components/editToggleSwitch.component";
import ConfidentialBusinessInformation from "../components/confidentialBusinessInformation.component";
import AdditionalFactoryInformation from "../components/FacilityDetails/facilityAdditionalInformation.component";
import { PageStyles } from "../styles";
import {
  selectAuditList,
  selectAuditListForFactory,
  selectFactoriesWhichAreInProgressOfCapManagement,
  selectMostRecentAuditIdForFactory,
} from "../selectors/audit.selector";
import { getListOfAllAudits, getAuditById } from "../reducers/audit.reducer";
import FactoryCAPStatus from "./factoryCapStatus.component";

const FacilityDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const selectedFactoryData = useSelector(selectFactoryData);
  const [isLoading, setIsLoading] = useState(false);
  const supplierId = useSelector(selectSupplierId);

  useEffect(() => {
    const fetchFactoryData = async () => {
      if (!selectedFactoryData || selectedFactoryData.id !== params.factoryId) {
        setIsLoading(true);
        try {
          await dispatch(
            getFactoryById({
              supplierId: supplierId,
              factoryId: params.factoryId,
            })
          );
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchFactoryData();
  }, [params.factoryId, supplierId, dispatch]);

  const selectedTab = useSelector(selectFacilityPageTab);

  useEffect(() => {
    dispatch(
      getListOfAllAudits({
        limit: 99,
        offset: 0,
        supplierId: supplierId,
      })
    );
  }, []);

  const selectAuditId = useSelector((state) =>
    selectMostRecentAuditIdForFactory(state, params.factoryId)
  );


  const factoriesWhichAreInProgressOfCapManagement = useSelector(
    selectFactoriesWhichAreInProgressOfCapManagement
  );
  return (
    <Box sx={PageStyles.containerBox}>
      <LeftSideNavbar />
      <Box sx={PageStyles.containerBoxExceptNavbar}>
        <Box sx={PageStyles.firstSectionWithBreadcrumbsAndName}>
          <TopBreadcrumbs />
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography gutterBottom sx={PageStyles.name}>
                {selectedFactoryData?.name}
                {/* {selectedFactoryData?.metadata?.tier && (
                  <Box
                    sx={PageStyles.tierAndPrivateLabel}
                    size="small"
                    variant="outlined"
                  >
                    Tier {selectedFactoryData?.metadata?.tier}
                  </Box>
                )}
                {selectedFactoryData?.metadata?.privateLabel === "Yes" && (
                  <Box
                    sx={PageStyles.tierAndPrivateLabel}
                    size="small"
                    variant="outlined"
                  >
                    Private Label
                  </Box>
                )} */}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  backgroundColor: "#2D3282",
                  borderRadius: "8px",

                  padding: "8px 12px",
                  fontSize: "14px",
                  fontWeight: "600",
                  mr: 0,
                }}
                disabled={true}
              >
                Send 4imprint a note
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Divider />

        <Grid container spacing={2} sx={PageStyles.secondSectionGrid}>
          <Grid item xs={12} md={12}>
            <FacilityInformation />
          </Grid>
          {/* Internal Information */}
          {/* <Grid item xs={12}>
            <FacilityInternalInformation />
          </Grid> */}
          {/* <Grid item xs={12} md={4}>
            <ConfidentialBusinessInformation
              title="Confidential Business Information"
              confidential={true}
              type={"factory"}
            />
          </Grid> */}
          {/* <Grid item xs={12}>
            <AdditionalFactoryInformation />
          </Grid> */}
          <Grid item xs={12}>
            <Card sx={PageStyles.cardForTabsAndBelow}>
              <Grid container>
                <Grid item xs={12}>
                  <FacilityTabs />
                </Grid>

                <Grid item xs={12}>
                  {selectedTab === "status" ? (
                    <>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            color: "#344054",
                            fontSize: "18px",
                            fontWeight: "700",
                            mt: 4,
                            mb: factoriesWhichAreInProgressOfCapManagement[
                              params.factoryId
                            ]
                              ? 3
                              : 2,
                          }}
                        >
                          Details from Most recent audit
                        </Typography>
                        {factoriesWhichAreInProgressOfCapManagement[
                          params.factoryId
                        ] && <FactoryCAPStatus />}
                      </Grid>
                      <FacilityComplianceStatus />
                    </>
                  ) : (
                    <FacilityAuditList />
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default FacilityDetails;
