import React from "react";
import { Box, Button, Typography, Paper, Grid } from "@mui/material";
import CapManagementModal from "../components/capManagementModal.component";
import { selectModalStateForManageCAPs } from "../selectors/audit.selector";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getListOfAuditsInCapManagement,
  setModalStateForManageCAPs,
} from "../reducers/audit.reducer";
import { selectSupplierId } from "../selectors/login.selector";

const FactoryCAPStatus = () => {
  const params = useParams();
  const factoryId = params.factoryId;
  const supplierId = useSelector(selectSupplierId);
  const dispatch = useDispatch();
  const modalStateForManageCAPs = useSelector(selectModalStateForManageCAPs);
  const handleManageCAPsModalOpen = () => {
    dispatch(getListOfAuditsInCapManagement({ supplierId, factoryId }));
    dispatch(setModalStateForManageCAPs(true));
  };
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={2}
      border={1}
      borderColor="grey.300"
      borderRadius={2}
      boxShadow={1}
      bgcolor="background.paper"
    >
      <Grid container spacing={2}>
        {/* First row: CAP Status text and Open CAP Management button */}
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography
                sx={{ color: "#344054", fontSize: "18px", fontWeight: "700" }}
              >
                CAP Status
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  textTransform: "none",
                  borderRadius: "8px",
                  border: "1px solid #2D3282",
                  background: "#2D3282",
                  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                }}
                onClick={handleManageCAPsModalOpen}
              >
                Open CAP Management
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {/* Second row: Full-width Ongoing status box */}
        <Grid item xs={12}>
          <Box
            elevation={2}
            sx={{
              textAlign: "center",

              borderRadius: 1,
              borderRadius: "6px",
              border: "1px solid rgba(234, 170, 8, 0.30)",
              background: "rgba(234, 170, 8, 0.10)",
              height: "58px",
            }}
          >
            <Typography
              sx={{
                color: "#EAAA08",
                fontSize: "36px",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              Ongoing
            </Typography>
          </Box>
          {modalStateForManageCAPs && (
            <CapManagementModal factoryId={factoryId} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default FactoryCAPStatus;
