import React from "react";
import { List, ListItem, ListItemText, Typography, Link } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useSelector } from "react-redux";
import { selectFactoryData } from "../../selectors/factory.selector";
import { selectAuditData } from "../../selectors/audit.selector";

const AuditReportResources = () => {
  const selectedAuditData = useSelector(selectAuditData);
  const selectedFactoryData = useSelector(selectFactoryData);

  return (
    <List sx={{ width: "100%", pl: 3, pr: 1 }}>
      {/* Section 1 */}
      <Typography
        variant="h6"
        gutterBottom
        sx={{ mt: 2, color: "#475467", fontSize: 16, fontWeight: 700 }}
      >
        CAP Development Guidance
      </Typography>
      <List sx={{ pl: 2 }}>
        <ListItem>
          <Link href="https://drive.google.com/file/d/1HnYbGT4-sc_cU-WlUqr7QTIEyv4hrD7n/view?usp=drive_link" target="_blank" rel="noopener">
          4imprint CAP Guidance
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://drive.google.com/file/d/1whhCXXcWHa4bYuALUsXtRUsghflCwLv5/view?usp=drive_link" target="_blank" rel="noopener">
            ElEVATE CAP Development Guidance
          </Link>
        </ListItem>
      </List>

      {/* Section 2 */}
      <Typography
        variant="h6"
        gutterBottom
        sx={{ mt: 2, color: "#475467", fontSize: 16, fontWeight: 700 }}
      >
        Job Hazard Analysis
      </Typography>
      <List sx={{ pl: 2 }}>
        <ListItem>
          <Link href="https://drive.google.com/file/d/1_rT7NJ4c-QuXQWYTuZ4tAQEcc-BLCnGO/view?usp=drive_link" target="_blank" rel="noopener">
            Conduct a hazard assessment
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://drive.google.com/file/d/1ZuBV36Meyj7K5fck7j5wnzXNIsh9uOFm/view?usp=drive_link" target="_blank" rel="noopener">
          Job Hazard Analysis Example
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://docs.google.com/document/d/1-Rm6YyGN6R3TMOzSMt7CDxL6EQrw_teE/edit?usp=sharing&ouid=113706279055944205209&rtpof=true&sd=true" target="_blank" rel="noopener">
          Sample Job Hazard Analysis
          </Link>
        </ListItem>
      </List>
      {/* Section 2 */}
      <Typography
        variant="h6"
        gutterBottom
        sx={{ mt: 2, color: "#475467", fontSize: 16, fontWeight: 700 }}
      >
        Environment
      </Typography>
      <List sx={{ pl: 2 }}>
        <ListItem>
          <Link href="https://drive.google.com/file/d/16ccqjCKXRSBSVq0SirqCiYWXVeAhDZNC/view?usp=sharing" target="_blank" rel="noopener">
          Volume-to-Weight Conversion Factors
          </Link>
        </ListItem>
      </List>
    </List>
  );
};

export default AuditReportResources;
