import React, { useEffect } from "react";

import { getListOfAllAudits } from "../../reducers/audit.reducer";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectAuditList } from "../../selectors/audit.selector";

import AuditList from "../auditList.component";
import { selectSupplierId } from "../../selectors/login.selector";

const SupplierFacilityAuditsTabList = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const supplierId = useSelector(selectSupplierId);
  useEffect(() => {
    dispatch(
      getListOfAllAudits({
        limit: 10,
        offset: 0,
        supplierId: supplierId,
      })
    );
  }, [params.supplierId]);

  const selectAudits = useSelector(selectAuditList);

  return <AuditList selectAudits={selectAudits} />;
};

export default SupplierFacilityAuditsTabList;
