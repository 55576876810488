import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectFactoryData } from "../../selectors/factory.selector";

import RiskCard from "../riskCard.component";
import FacilityIssuesCard from "./facilityIssuesCard.component";
import PastCAP from "./factoryActionNeeded.component";
import FactoryActionNeeded from "./factoryActionNeeded.component";
import TwoLineCard from "../twoLineCard.component";
import { FacilityStyles } from "../../styles";
import { calculateAuditDueDataData } from "../../utils/lib";

const FactoryMostRecentAuditDetails = ({ selectedFactory }) => {

  const auditDueData = calculateAuditDueDataData(selectedFactory?.latestAudit?.lastAuditDateTimestamp);

  return (
    <>
      {/* <Typography sx={FacilityStyles.mostRecentAuditHeading}>
        Details from Most Recent Audit
      </Typography> */}
      <Grid container spacing={4} sx={{ mt: 1 }}>
        <Grid item xs={7}>
          <FacilityIssuesCard latestAuditData={selectedFactory.latestAudit} />
        </Grid>
        <Grid item xs={5}>
          <TwoLineCard
            title="Audit Score"
            nextAuditDate={
              selectedFactory?.latestAudit?.risk?.auditScore || "Coming Soon."
            }
          />
          <TwoLineCard
            title="Next Audit Due Date"
            nextAuditDate={auditDueData?.nextAuditDueDate || "N/A"}
            textColor={auditDueData?.isPastDue ? '#B42318' : 'inherit'}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FactoryMostRecentAuditDetails;
