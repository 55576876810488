import React from "react";
import {
  Grid,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Link,
} from "@mui/material";
import { useSelector } from "react-redux";
import { selectAuditCAPUploadedProof } from "../../selectors/audit.selector";

const AuditCapDetailsStatusUploadedProof = () => {
  const uploadedProof = useSelector(selectAuditCAPUploadedProof);
  return (
    uploadedProof?.length > 0 && (
      <Grid>
        {/* <Typography
          sx={{
            color: "#475467",
            fontSize: "30px",
            fontWeight: 500,
            textDecorationLine: "underline",
            my: 4,
            overflowWrap: "break-word",
            wordWrap: "break-word",
            hyphens: "auto",
          }}
        >
          Uploaded Proof
        </Typography> */}
        <Box
          sx={{
            padding: "12px",
            borderRadius: "12px",
            background: "#b6b6b614",
            mt: 2,
          }}
        >
          <List sx={{ p: 0 }}>
            {uploadedProof.map((proof, index) => (
              <ListItem key={index} sx={{ p: 1 }}>
                <Link
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    color: "#45464E",
                    textDecoration: "underline",
                    textDecorationColor: "#45464E",
                    "&:hover": {
                      textDecorationColor: "rgba(69, 70, 78, 0.8)",
                    },
                  }}
                  href={proof.url}
                  target="_blank"
                >
                  {proof.name}
                </Link>
              </ListItem>
            ))}
          </List>
        </Box>
      </Grid>
    )
  );
};

export default AuditCapDetailsStatusUploadedProof;
