import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Modal,
  Card,
  Grid,
  CircularProgress,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import BulletIcon from "@mui/icons-material/Circle";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectEmail, selectSupplierId } from "../../selectors/login.selector";
import { checkInternalEmail } from "../../utils/lib";
import { getListOfAllAudits } from "../../reducers/audit.reducer";
import AuditDocumentDrop from "../AuditDetails/auditDocumentDrop.component";
import AuditCapDetailsStatusUploadedProof from "./AuditCapDetailsStatusUploadedProof.component";
const CapApprovedUploadImageProof = ({
  immediateActions,
  longTermActions,
  status,
  rootCauseAnalysis,
  responsiblePerson,
  targetDate,
  rootCause,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [uploading, setUploading] = useState(false);

  const supplierId = useSelector(selectSupplierId);
  const userEmail = useSelector(selectEmail);
  const isInternalUser = checkInternalEmail(userEmail);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setUploading(true);
    setTimeout(() => {
      refreshAuditList();
      setUploading(false);
    }, 5000); // Wait for 5 seconds before refreshing the list
  };
  const refreshAuditList = () => {
    dispatch(
      getListOfAllAudits({
        limit: 10,
        offset: 0,
        supplierId: supplierId,
      })
    );
  };

  const [uploadedFile, setUploadedFile] = useState(null);

  // Handler to simulate file upload
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedFile(file);
    }
  };

  return (
    <Box sx={{ mt: 5, mb: 3 }}>
      {/* Title */}
      <Typography
        sx={{ mb: 2, fontWeight: "400", fontSize: "24px", color: "#475467" }}
      >
        Approved CAP
      </Typography>

      <Divider sx={{ mb: 3 }} />
      <Grid container sx={{ mb: 3 }}>
        <Grid item xs={4}>
          <Typography
            sx={{
              mb: 1,
              fontWeight: "700",
              fontSize: "16px",
              color: "#475467",
            }}
          >
            Root Cause
          </Typography>
          <List dense sx={{ paddingLeft: 0, ml: 0 }}>
            {[rootCause].map((action, index) => (
              <ListItem key={index}>
                {/* <ListItemIcon
              sx={{  paddingLeft: 0, marginLeft: 0 }}
            >
              <BulletIcon sx={{ color: "black", fontSize: "10px" }} />
            </ListItemIcon> */}
                <ListItemText primary={action} />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={8}>
          <Typography
            sx={{
              mb: 1,
              fontWeight: "700",
              fontSize: "16px",
              color: "#475467",
            }}
          >
            Root Cause Analysis
          </Typography>
          <List dense sx={{ paddingLeft: 0, ml: 0 }}>
            {[rootCauseAnalysis].map((action, index) => (
              <ListItem key={index}>
                {/* <ListItemIcon
              sx={{  paddingLeft: 0, marginLeft: 0 }}
            >
              <BulletIcon sx={{ color: "black", fontSize: "10px" }} />
            </ListItemIcon> */}
                <ListItemText primary={action} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
      {/* Immediate Corrective Action */}
      <Typography
        sx={{ mb: 1, fontWeight: "700", fontSize: "16px", color: "#475467" }}
      >
        Immediate Corrective Action
      </Typography>
      <List dense sx={{ paddingLeft: 0, ml: 0 }}>
        {[immediateActions].map((action, index) => (
          <ListItem key={index}>
            {/* <ListItemIcon
              sx={{  paddingLeft: 0, marginLeft: 0 }}
            >
              <BulletIcon sx={{ color: "black", fontSize: "10px" }} />
            </ListItemIcon> */}
            <ListItemText primary={action} />
          </ListItem>
        ))}
      </List>

      {/* <Typography variant="body1" sx={{ fontWeight: "bold", mt: 2, mb: 2 }}>
        Changes suggested: {immediateActions[0]}
      </Typography> */}

      {/* Long Term Preventative Action */}
      <Typography
        sx={{
          mb: 1,
          fontWeight: "700",
          fontSize: "16px",
          color: "#475467",
          mt: 3,
        }}
      >
        Long Term Preventative Action
      </Typography>
      <List dense>
        {[longTermActions].map((action, index) => (
          <ListItem key={index}>
            <ListItemText primary={action} />
          </ListItem>
        ))}
      </List>

      <Grid
        container
        sx={{
          backgroundColor: "#F9FAFB",
          borderRadius: 2,
          px: 2,
        }}
      >
        <Grid item xs={6}>
          <Typography
            sx={{
              mb: 1,
              fontWeight: "700",
              fontSize: "16px",
              color: "#475467",
              mt: 3,
            }}
          >
            Responsible Person
          </Typography>
          <List dense>
            {[responsiblePerson].map((action, index) => (
              <ListItem key={index}>
                <ListItemText primary={action} />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={6}>
          <Typography
            sx={{
              mb: 1,
              fontWeight: "700",
              fontSize: "16px",
              color: "#475467",
              mt: 3,
            }}
          >
            Target Date
          </Typography>
          <List dense>
            {[targetDate].map((action, index) => (
              <ListItem key={index}>
                <ListItemText primary={action} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
      {/* Upload Proof Section */}
      <Typography
        sx={{
          mt: 5,
          mb: 2,
          fontWeight: "400",
          fontSize: "24px",
          color: "#475467",
        }}
      >
        {status === "SITE_ADDING_PROOF"
          ? "Upload Proof"
          : status === "SITE_ADDED_PROOF"
          ? "Uploaded Proof Under Review"
          : "Approved Proof"}
      </Typography>

      <Divider sx={{ mb: 2 }} />

      {/* File Upload Button */}
      {(status === "BRAND_APPROVED_PLAN" || status === "SITE_ADDING_PROOF") && (
        <Button
          sx={{
            fontSize: "14px",
            fontWeight: 600,
            color: "#344054",
            borderRadius: "8px",
            padding: "10px 14px",
            border: "1px solid var(--Gray-3000, #D0D5DD)",
            background: "var(--Base-White, #FFF)",
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "none",
          }}
          onClick={handleOpen}
          startIcon={<UploadFileIcon sx={{ color: "#667085" }} />}
        >
          Upload Proof
        </Button>
      )}

      <AuditCapDetailsStatusUploadedProof />
      <Modal open={open} onClose={handleClose}>
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1000,
            p: 4,
            boxShadow:
              "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
            border: "1px solid rgba(234, 236, 240, 1)",
            borderRadius: "12px",
            py: 2,
          }}
        >
          <AuditDocumentDrop
            organisationId={params.organisationId}
            supplierId={params.supplierId}
            factoryId={params.factoryId}
            onClose={handleClose}
            mode="process"
          />
        </Card>
      </Modal>

      {/* Display the uploaded file name */}
      {uploadedFile && (
        <Typography variant="body2" sx={{ mt: 2 }}>
          Uploaded file: {uploadedFile.name}
        </Typography>
      )}
    </Box>
  );
};

export default CapApprovedUploadImageProof;
