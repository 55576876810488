import moment from "moment";

export const getValidData = (data) => {
  return data === "-" || data == null ? "" : data;
};


export const checkInternalEmail = (email) => {
  return email.includes("esger") || email.includes("elm");
};

export const calculateAuditDueDataData = (timestamp) => {
  const currentDate = moment();
  const inputDate = moment(timestamp);
  const nextAuditDueDate = inputDate.clone().add(2, 'years');
  const isPastDue = nextAuditDueDate.isBefore(currentDate);
  const daysUntilNextAudit = nextAuditDueDate.diff(currentDate, 'days');
  return {
    lastAuditDate: inputDate.format('MMM. D, YYYY'),
    nextAuditDueDate: nextAuditDueDate.format('MMM. D, YYYY'),
    isPastDue,
    daysUntilNextAudit: isPastDue ? -Math.abs(daysUntilNextAudit) : daysUntilNextAudit
  };
};
